.NewLoadin{
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    align-content: center;
    margin: auto 0 auto;
    background-color: #fff;
    z-index: 900;
    padding-top: 30%;
    padding-bottom: 50px;
    margin-top: -200px;
    position: relative;
}

#pot {
  bottom: 15%;
  position: absolute;
  -webkit-animation: linear infinite;
  -webkit-animation-name: run;
  -webkit-animation-duration: 5s;
}
@-webkit-keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 100%;
  }
  100% {
    left: 0;    
  }
}