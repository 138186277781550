#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #ffffff;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
  }
  
  .swiper {
    width: 100%;
    height: 132px;
    margin-top: 30px;
    margin-bottom: 10px;
    padding-left: 10px;
    padding-right: 5px;
  }
  
  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    height: 110px;
    width: 110px;
    margin-bottom: 10px;
    color: #000000;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  