#app {
    height: 100%;
  }
  html,
  body {
    position: relative;
    height: 100%;
  }
  
  body {
    background: #ffffff;
    margin: 0;
    padding: 0;
    padding-bottom: 10px;
  }
  
  .swiper2 {
    width: 100%;
    height: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
  }
  
  .swiper-slide2 {
    text-align: center;
    font-size: 18px;
    background: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 500px;
    height: 50px;
    margin-bottom: 10px;
    color: #000000;
  }
  
  .swiper-slide2 img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  