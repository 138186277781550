@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin: 0;
  padding: 0;
  font-family:Arial, Helvetica, sans-serif;

}

body{
  
  background-color: #ffffff;

}

::-webkit-scrollbar{
  width: 5px;
  margin-left: 0;
  height: 10x;
  overflow-x: hidden;
}



::-webkit-scrollbar-thumb{
  background: #10B981;
  border-radius: 10px;
  overflow-x: hidden;
}



.sidebar{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(255, 255, 255) ;
  display: flex;
  flex-direction: column;
  padding: 0;
  z-index: 1000;
  box-shadow: 4px 0px 8px #7a7a7a;
}

.scrollbox{
  overflow: auto; 
  padding-right: 0;
  
}



.content{
  position: fixed;
  flex-direction: column;
  padding: 0;
  display: flex;
  height: 100%;
  left: 700px;
  width: calc(100% - 700px);
}

 .content-wrapper{
  
    text-align: center;
    top: 0;
    left: 0;
    width: 100% ;
    
}
@media (max-width: 970px) {
  .content {
    display: none;
  }
  .sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(255, 255, 255) ;
    display: flex;
    flex-direction: column;
    padding: 0;
    z-index: 1000;
    overflow-x: hidden;
  }
  .scrollbox{
    overflow: auto; 
    overflow-x: hidden;
  }

} 






/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.split-container {
  display: flex;
 
}


.left-screen {
  width: 35%;
  background-color: #ffffff;
  box-sizing: border-box;
  overflow-y: scroll; 
    
}

.left-screen::-webkit-scrollbar {
  width: 6px;
}


.right-screen {
    width: 65%;
    background-color: #F0EB8D;
    padding: 20px;
    box-sizing: border-box;
    height: 100%; 
    position: fixed; 
    top: 0; 
    right: 0; 
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding-top: 20%;
    text-align: center;
}

@media (max-width: 768px) {
  .right-screen {
    display: none;
  }
  .left-screen {
    width: 100%;
    background-color: #ffffff;
    box-sizing: border-box;
    height: 100%;
    overflow-x: scroll;
  }
} */